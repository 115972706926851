<template>
	<div>
		<!-- 首页固定区 -->
		<div class="index-wrap " style="margin-bottom: -5px;">
			<div class="index-left">
				<div style="background: #ffc400;color:#000">印品分类</div>
				<div class="shop-list">
					<div class="list-item" v-for="(item, index) in goodsCategoryTree" :key="index" @mouseover="selectedCategory = item.category_id" @mouseleave="selectedCategory = -1"
					 >
						<router-link :to="{ path: '/customized'}" target="_blank"
						>
							<div class="list-item-left">
								<img class="category-img"
									v-if="item.image"
								<p class="category-name">{{item.category_name }}</p>
							</div>
						</router-link>
						<div class="item-itm " v-if="0==true" :class="{'item-itm-img':categoryConfig.img == 1}">
							<router-link v-for="(second_item, second_index) in item.child_list" :key="second_index" :to="{ path: '/customized'}" target="_blank"
							 v-show="second_index < 3"
							 style="display:inline-block;">
								{{ second_item.short_name?second_item.short_name : second_item.category_name }}
							</router-link>
						</div>
						<div class="cate-part" v-if="item.child_list" :class="{ show: selectedCategory == item.category_id }">
							<div class="cate-part-col1">
								<div class="cate-detail">
									<dl class="cate-detail-item" v-for="(second_item, second_index) in item.child_list" :key="second_index"
									>
										<dt class="cate-detail-tit">
											<router-link target="_blank" :to="{ path: '/customized' }">
												{{ second_item.category_name }}
											</router-link>
										</dt>
										<dd class="cate-detail-con" v-if="second_item.child_list">
											<router-link 
												v-for="(third_item, third_index) in second_item.child_list"
												:key="third_index"
												target="_blank"
												:to="{ path: '/customized'}"
											>
											<p class="category-name">{{third_item.category_name }}</p>
											</router-link>
										</dd>
									</dl>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="index" style="cursor: pointer;">
				<div class="banner" style="height: auto;">
					<el-carousel height="386px" arrow="hover">
						<el-carousel-item v-for="item in adList" :key="item.adv_id" v-if="adList.length>0">
							<el-image :src="item.adv_image" fit="cover"/>
						</el-carousel-item>
					</el-carousel>
					<div>
						<img src="../../assets/images/index/yins.png" style="width: 100%;"/>
					</div>
				</div>
			</div>
		</div>

		<div class="content" style="cursor: pointer;margin-top: 20px;">
			<div>
				<img src="../../assets/images/index/yin_s.jpg" style="width: 100%;"/>
			</div>
		</div>
	</div>
</template>
<script>
	import { tree , categoryConfig} from '@/api/goods/goodscategory';
	
	export default {
	  data: () => {
	    return {   
			goodsCategoryTree: [],
			selectedCategory: -1,
			adList:[{
				"adv_id": 40,
				"adv_image":require('../../assets/images/index/back4.jpg')
			}, {
				"adv_id": 41,
				"adv_image": require('../../assets/images/index/back3.jpg')
			}, {
				"adv_id": 40,
				"adv_image": require('../../assets/images/index/back2.jpg')
			}, {
				"adv_id": 39,
				"adv_image": require('../../assets/images/index/back1.jpg')
			}]
		}
		},
	  created() { 
		  this.getTree()
	  }, 
	  methods: {
		  getTree() {
		  	tree({level: 3,template: 2,type:1})
		  		.then(res => {
		  			if (res.code == 0 && res.data) {
		  				this.goodsCategoryTree = res.data || [];
		  				for(let i =0;i<this.goodsCategoryTree.length;i++) {
		  					if(this.goodsCategoryTree[i].child_list > 3) {
		  						this.isHide = true
		  					}
		  				}
		  			}
		  		})
		  		.catch(err => {
		  			this.$message.error(err.message);
		  		});
		  },
	  }
	};
</script>

<style lang="scss" scoped>
	@import './_index/index.scss';
	.index-left{
		position: relative;
		width: 210px;
		height: 41px;
		color: #fff;
		float: left;
		padding: 10px 10px 11px 10px;
		box-sizing: border-box;
		text-align: center;
		z-index: 300000;
	}
	.shop-list {
		width: 195px;
		// height: 500px;
		overflow-y: auto;
		overflow-x: hidden;
		background: #fff;
		// box-shadow: -1px 3px 12px -1px rgba(0, 0, 0, 0.3);
		&::-webkit-scrollbar{
			display: none;
		}
		a:hover {
			color: $base-color;
	
		}
		.list-item {
			padding-left: 12px;
			box-sizing: border-box;
			line-height: 34px;
			// min-height:50px;
			// height: 45px;
			// line-height: 50px;
			padding:6px 13px;
			a {
				display: flex;
				justify-content: space-between;
				align-items: center;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				// width: 95%;
				// display: block;
			//	color:  #000;
				>div{
					display: flex;
					align-items: center;
				}
			}
			&:hover {
			//	background-color: #000;
				-webkit-transition: 0.2s ease-in-out;
				-moz-transition: -webkit-transform 0.2s ease-in-out;
				-moz-transition: 0.2s ease-in-out;
				transition: 0.2s ease-in-out;
				a:hover {
						color: red;
				}
			}
			span:hover {
				color: #FD274A;
			}
			.category-img{
				width:17px;
				height: 17px;
				margin-right: 10px;
			}
			.category-name{
				overflow:hidden;
				text-overflow:ellipsis;
				white-space:nowrap;
			}
			.item-itm {
				font-size: 14px;
				line-height: 15px; 
				height: 28px;
				overflow: hidden;
				
				a{
					margin-top: 5px;
					margin-right: 10px;
					color:#BFBFBF;
					&:hover{
						color: #FD274A !important;
					}
				}
				
				&.item-itm-img{
					margin-left: 27px;
				}
			}
			.cate-part {
				display: none;
				position: absolute;
				left: 204px;
				top: 0;
				z-index: auto;
				// width: 998px;
				width: 760px;
				height: 498px;
				overflow-y: auto;
				border: 1px solid #f7f7f7;
				background-color: #fff;
				-webkit-box-shadow: 2px 0 5px rgba(0, 0, 0, 0.05);
				-moz-box-shadow: 2px 0 5px rgba(0, 0, 0, 0.05);
				box-shadow: 2px 0 5px rgba(0, 0, 0, 0.05);
				-webkit-transition: top 0.25s ease;
				-o-transition: top 0.25s ease;
				-moz-transition: top 0.25s ease;
				transition: top 0.25s ease;
				&.show {
					display: block;
				}
				&::-webkit-scrollbar{
					display: none;
				}
			}
			.cate-part-col1 {
				float: left;
				width: 100%;
				.cate-detail-item {
					position: relative;
					min-height: 36px;
					padding-left: 20px;
					&:last-child{
						margin-bottom: 30px;
					}
					.cate-detail-tit { 
						text-align: left;
						margin-top:10px;
						margin-right: 20px;
						white-space: nowrap;
						text-overflow: ellipsis;
						font-weight: 700;
						a {
							display: block;
							color: #333333;
						}
						a:hover{
							color: #FD274A;
						}
						
					}
					.cate-detail-con {
						overflow: hidden;
						// padding: 6px 0 6px 17px;
						display: flex;
						flex-wrap: wrap;
						// border-top: 1px dashed #eee;
						a {
							justify-content: start;
							font-size: 12px;
							height:30px;
						    /* float: left; */
						    // margin: 4px 40px 4px 0;
						    // padding: 0 10px;
						    white-space: nowrap;
						    line-height: 30px;
						    color: #666;
							// width: calc((100% ) / 10);
							display: flex;
							box-sizing: border-box;
							// margin-top: 20px;
							padding-right:20px;
							.cate-detail-img{
								width:30px;
								height: 30px;
								margin-right: 10px;
							}
							&:nth-child(4n+4){
								margin-right: 0;
							}
						}
						a:hover{
							color: #FD274A;
						}
					}
					&:first-child .cate-detail-con {
						border-top: none;
					}
				}
			}
	
			// .sub-class-right {
			// 	display: block;
			// 	width: 240px;
			// 	height: 439px;
			// 	float: right;
			// 	border-left: solid 1px #e6e6e6;
			// 	overflow: hidden;
			// 	.adv-promotions {
			// 		display: block;
			// 		height: 441px;
			// 		margin: -1px 0;
			// 		a {
			// 			background: #fff;
			// 			display: block;
			// 			width: 240px;
			// 			height: 146px;
			// 			border-top: solid 1px #e6e6e6;
			// 			img {
			// 				background: #d3d3d3;
			// 				width: 240px;
			// 				height: 146px;
			// 			}
			// 		}
			// 	}
			// }
		}
	}
</style>